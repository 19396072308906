import React, { Component } from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ItemListing from "../components/item-listing";
import Card from "../components/card";
import styled from "styled-components";
import HeroArea from "../components/hero-area";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import FAQ from "../components/FAQ";
  
import ReviewSitesInlineRatings from "../components/ReviewSitesInlineRatings";
import HeroRatingStars from "../components/hero-rating-stars";
import Button from "../components/atoms/Button";
import { ArrowRight } from "lucide-react";


const MainContentContainer = styled.div`
  h1 {
    margin-bottom: 0.25em;
  }
`;

const SidebarContainer = styled.div``;
const Spacer = styled.div`
  height: 5px;
`;
const MapTitle = styled.p`
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 600;
`;

class About extends Component {
  render() {
    const pageData = this.props.data.contentfulPage;

    const showContactForm = this.props.location.pathname.includes("contact");

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => <Card key={index} sidebar={sidebar} />);

      return sideBarCards;
    };

    // return    <Layout pageProps={this.props} headerTransparent={true}><h1>Page is coming soon.</h1></Layout>
    return (
      <Layout pageProps={this.props}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
          keywords={pageData.metaKeywordsList ? pageData.metaKeywordsList : []}
          robots={pageData.metaRobotsContent}
        /> 
 
        <div className="relative isolate">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#dc1939] to-[#dc1939] opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-16 pt-16 sm:pt-32 lg:px-8 lg:pt-16">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  {[pageData && pageData.heroArea][0].heroTitle}
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                 {[pageData && pageData.heroArea][0].description.description}
                  </p>
                  <div className="mt-10 flex items-center gap-x-6">
                    <ReviewSitesInlineRatings/>
                   
                  </div>
                  <div className="mt-10">
                  <Button color="brandGradient" size="xl"  >
                Get a FREE Estimate <ArrowRight size={18} />
              </Button></div>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="https://images.ctfassets.net/dnje5q8u1u3a/3aB128GqwUudMaDo0iK5Sf/3a8ef608496ee113b653aab288d23df3/Resized_20231122_143852.jpeg?h=400"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="https://images.ctfassets.net/dnje5q8u1u3a/6awvekaCdWqMto4ohLfCgp/a95cdb9a51898ca92f1fc8c764a69ea5/sds.jpg?h=400"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://images.ctfassets.net/dnje5q8u1u3a/S4Ioz4bTAOpVsMxbSeOsO/624ec36af2ab6af3bd63b60b2e4ac387/IMG_0231.jpeg?h=350"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="https://images.ctfassets.net/dnje5q8u1u3a/3xaXIMgJhiGDlV2NRNjQgl/12907ac1df3e49ca52d2b7cc2e318950/Screenshot_2024-02-25_at_4.02.05_PM.png?h=300"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://images.ctfassets.net/dnje5q8u1u3a/7GpbutKRTiAytgA4621ekT/15e63af468b2fe36b4a9bfa22dc566e9/Screenshot_2024-02-25_at_11.18.39_PM.png?h=300"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
           {/* <PageTitleHeroArea
        heroContent={[pageData && pageData.heroArea]}
        showForm={false}
      /> */}
 

        <div class="mx-auto max-w-7xl px-6  lg:px-8 py-12 md:py-16">
        <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">History</h2>
          <div class="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
            <div class="lg:w-full lg:max-w-2xl lg:flex-auto">
               <div class="ax-w-xl text-base leading-7 text-gray-700 prose">
              <div dangerouslySetInnerHTML={{ __html: pageData.body.body }} /> 
                  </div>
            </div>
            <div class="lg:flex lg:flex-auto lg:justify-center">
              <dl class="w-64 space-y-8 xl:w-80">
                <div class="flex flex-col-reverse gap-y-4">
                    <dt class="text-base leading-7 text-gray-600">Home improvement projects completed</dt>
                    <dd class="text-5xl font-semibold  font-display  text-brand-700">3,500+</dd>
                  </div>
                <div class="flex flex-col-reverse gap-y-4">
                    <dt class="text-base leading-7 text-gray-600">Average rating from customers</dt>
                    <dd class="text-5xl font-semibold  font-display   text-brand-700">4.9/5</dd>
                  </div>
                <div class="flex flex-col-reverse gap-y-4">
                    <dt class="text-base leading-7 text-gray-600">based contractors and specialists</dt>
                    <dd class="text-5xl font-semibold  font-display   text-brand-700">Michigan</dd>
                  </div> 
              </dl>
            </div>
          </div>
        </div>
      </div>

<div className="bg-white shadow-md py-8 md:py-16">
      <div className="mx-auto  max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Team</h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
            They will make your home’s upgrade a smooth process from start to finish.
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-12 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none xl:grid-cols-6"
          >
            {pageData.team.map((person) => (
              <li key={person.title}>
              
                <GatsbyImage
                 className="mx-auto h-40 w-40 rounded-xl top" 
        image={getImage(person.gatsbyImageData)}
        // style={{ minHeight: '100%', margin: '0 auto' }}
        alt={person.title}
      />

                <h3 className="mt-4 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.title}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.description}</p>
              </li>
            ))}
          </ul>
        </div>

        
        <div>
        </div>
 
        </div>
        <FAQ/>
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query aboutPageQuery {
    contentfulPage(slug: {eq: "about/"}) {
      id
      pageTitle
      metaTitle
      metaDescription
      slug
      metaKeywordsList
      metaRobotsContent

    team {

      id
        title
        description
        gatsbyImageData(width: 370, quality: 100)
      }

      itemListing {
        id
        serviceTitle
        excerpt
        slug
        heroImage {
          gatsbyImageData(width: 370, quality: 70)
        }
      }

      heroArea {
        id
        name
        heroTitle
        description {
          description
        }
        heroImage {
          gatsbyImageData(width: 1000, quality: 80)
        }
        primaryActionBtnLabel
        primaryActionBtnLink
      }
      body {
        body
      }
      sidebar {
        id
        title
        content {
          id
          content
        }
        testimonialCategory {
          id
          name
        }
        cssClasses
      }
    }
  }
`;

//  mb-6  font-bold text-2xl md:text-3xl text-base md:text-lg text-neutral-600
